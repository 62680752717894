import React from 'react';

const LearnMore = () => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-[#6366F1] to-[#EC4899] flex flex-col text-white pt-16">
            <section className="flex flex-col items-center px-4 py-8 text-center max-w-7xl mx-auto">
                <h1 className="text-4xl md:text-5xl font-extrabold mb-6">Discover Placefy</h1>
                <p className="mb-6 text-base md:text-lg leading-relaxed">
                    Placefy is an innovative EdTech platform designed to foster a collaborative and engaging environment for coders and tech enthusiasts. Our platform offers a variety of features to support learning, collaboration, and career advancement in the tech industry.
                </p>
                <div className="w-full px-3 md:px-8">
                    <h2 className="text-3xl font-bold mb-4 text-left">Key Features</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 text-left">
                        {features.map((feature, index) => (
                            <div key={index} className="flex items-start bg-white bg-opacity-20 p-4 rounded-lg">
                                <div className="bg-white bg-opacity-30 p-3 rounded-full mr-4">
                                    {feature.icon}
                                </div>
                                <div>
                                    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                                    <p>{feature.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

const features = [
    {
        title: 'Collaborative Coding Environment',
        description: 'Connect with peers and industry professionals to code together and tackle challenging problems.',
        icon: (
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="m8 8-4 4 4 4m8 0 4-4-4-4m-2-3-4 14" />
            </svg>
        )
    },
    {
        title: 'Hackathons and Challenges',
        description: 'Participate in exciting hackathons and coding challenges to test your skills and earn credits.',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-trophy"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 21l8 0" /><path d="M12 17l0 4" /><path d="M7 4l10 0" /><path d="M17 4v8a5 5 0 0 1 -10 0v-8" /><path d="M5 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M19 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /></svg>
        )
    },
    {
        title: 'Credit System',
        description: 'Earn credits by solving challenges and redeem them for goodies or unlock premium content.',
        icon: (
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M12 6m-8 0a8 3 0 1 0 16 0a8 3 0 1 0 -16 0" />
                <path d="M4 6v6a8 3 0 0 0 16 0v-6" />
                <path d="M4 12v6a8 3 0 0 0 16 0v-6" />
            </svg>
        )
    },
    {
        title: 'Advanced Courses and Certifications',
        description: 'Access a wide range of advanced coding courses and certifications to enhance your knowledge and skills.',
        icon: (
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
                <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
                <path d="M6 9l12 0" />
                <path d="M6 12l3 0" />
                <path d="M6 15l2 0" />
            </svg>
        )
    },
    {
        title: 'Job Opportunities',
        description: 'Showcase your skills to top tech companies and secure job opportunities through our platform.',
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-briefcase"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" /><path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" /><path d="M12 12l0 .01" /><path d="M3 13a20 20 0 0 0 18 0" /></svg>
        )
    },
    {
        title: 'Subscription Plans',
        description: 'Choose from free and premium tiers to access additional features and content.',
        icon: (
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M16 19h6" />
                <path d="M19 16v6" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
            </svg>
        )
    }
];

export default LearnMore;