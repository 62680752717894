import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const Home = () => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [submissionStatus, setSubmissionStatus] = useState(null); // null, 'success', or 'error'

    const handleNotifyClick = () => {
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
    };

    const handleCloseConfirmation = () => {
        setSubmissionStatus(null); // Reset status when closing the confirmation
    };

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                await addDoc(collection(db, 'emails'), {
                    email: values.email,
                    subscribedAt: new Date(),
                });

                resetForm();
                setSubmissionStatus('success');
            } catch (error) {
                console.error('Error adding document: ', error);
                setSubmissionStatus('error');
            } finally {
                setPopupOpen(false);
            }
        },
    });

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-[#6366F1] to-[#EC4899] px-4 md:px-6">
            <div className="max-w-xl text-center mt-10 md:mt-0">
                <h1 className="text-4xl md:text-5xl font-bold text-white tracking-tight sm:text-6xl mb-6">Welcome to Placefy</h1>
                <p className="text-base md:text-lg text-gray-200 mb-6">
                    Placefy is your ultimate destination for collaborative learning and coding adventures. Get ready to unlock your full potential with our cutting-edge platform.
                </p>
                <div className="flex flex-row items-center justify-center gap-4 mb-6">
                    <button onClick={handleNotifyClick} className="bg-white text-[#6366F1] font-medium py-2 px-4 rounded-md hover:bg-gray-200 transition-colors">
                        Notify Me
                    </button>
                    <Link to="/learn-more" className="bg-green-400 text-white font-medium py-2 px-4 rounded-md hover:bg-green-500 transition-colors">
                        Learn More
                    </Link>
                </div>
                <div className="flex items-center justify-center gap-4">
                    <Link to="https://www.instagram.com/placefyofficial" target='_blank' className="text-gray-200 hover:text-white transition-colors" href="https://instagram.com">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                        </svg>
                        <span className="sr-only">Instagram</span>
                    </Link>
                    <Link to="https://x.com/PlacefyOfficial" target='_blank' className="text-gray-200 hover:text-white transition-colors" href="https://twitter.com">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                            <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
                        </svg>
                        <span className="sr-only">Twitter</span>
                    </Link>
                    <Link to="https://www.linkedin.com/company/placefyoffecial/" target='_blank' className="text-gray-200 hover:text-white transition-colors" href="https://linkedin.com">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
                            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                            <rect width="4" height="12" x="2" y="9"></rect>
                            <circle cx="4" cy="4" r="2"></circle>
                        </svg>
                        <span className="sr-only">LinkedIn</span>
                    </Link>
                </div>
            </div>

            {isPopupOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 px-3">
                    <div className="bg-white p-6 rounded-lg max-w-sm w-full space-y-4">
                        <div className="flex justify-end">
                            <button onClick={handleClosePopup} className="text-gray-400 hover:text-gray-600">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <line x1="18" y1="6" x2="6" y2="18"></line>
                                    <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                            </button>
                        </div>
                        <h2 className="text-xl sm:text-2xl font-bold text-gray-800">Stay in the Loop with Placefy</h2>
                        <form onSubmit={formik.handleSubmit} className="space-y-4">
                            <div>
                                <label htmlFor="email" className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    {...formik.getFieldProps('email')}
                                    className={`mt-1 block w-full px-3 py-2 border ${formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                                ) : null}
                            </div>
                            <button type="submit" className={`w-full font-semibold text-white py-2 px-4 rounded-md transition-colors ${formik.touched.email && formik.errors.email ? 'bg-[#7b58f8]/80 cursor-not-allowed' : 'bg-[#7b58f8] hover:bg-[#4b4fdc]'}`}>
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            )}

            {submissionStatus === 'success' && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 px-3">
                    <div className="bg-white p-6 rounded-lg max-w-sm w-full space-y-4 text-center">
                        <h2 className="text-2xl font-bold text-gray-800">Thank you!</h2>
                        <p className="text-gray-600">You have successfully subscribed to notifications.</p>
                        <button onClick={handleCloseConfirmation} className="w-full bg-[#6366F1] text-white py-2 px-4 rounded-md hover:bg-[#4b4fdc] transition-colors">
                            Close
                        </button>
                    </div>
                </div>
            )}

            {submissionStatus === 'error' && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 px-3">
                    <div className="bg-white p-6 rounded-lg max-w-sm w-full space-y-4 text-center">
                        <h2 className="text-2xl font-bold text-gray-800">Something went wrong!</h2>
                        <p className="text-gray-600">Please try again later.</p>
                        <button onClick={handleCloseConfirmation} className="w-full bg-[#6366F1] text-white py-2 px-4 rounded-md hover:bg-[#4b4fdc] transition-colors">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;