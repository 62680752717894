import React from 'react'
import LOGO from '../assets/Logo.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <header className='w-full absolute top-3 pl-3 md:pl-5'>
            <Link to="/" className='flex items-center'>
                <img src={LOGO} alt="Placefy" className='w-14 h-14 rounded-full' />
                <span className='text-3xl text-white font-sans font-semibold tracking-wider'>Placefy</span>
            </Link>
        </header>
    )
}

export default Navbar