import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAWkK-7J-iOxJApG3nJcovNejY2sPPR9T8",
    authDomain: "placefy-commingsoon.firebaseapp.com",
    projectId: "placefy-commingsoon",
    storageBucket: "placefy-commingsoon.appspot.com",
    messagingSenderId: "550950101141",
    appId: "1:550950101141:web:9fa5ae60b1aac5b02fbb9c",
    measurementId: "G-RSCTXE2S3L"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
